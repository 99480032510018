import { Permissions } from 'constants/permissions';
import { MenuItem } from './headerMenu';
import AuthStore, { UserInfoModal } from 'core/store/authStore/authStore';
import { isFinanceUser } from 'core/utils/utils';

const getInfo = () => {
    const userDetails: UserInfoModal = AuthStore.getUserInfo();
    const loggedInUserRoleId = userDetails.userInfo.roleId;
    const isThisFinanceUser = isFinanceUser(loggedInUserRoleId);

    return { isThisFinanceUser };
};

export const generateHeaderMenu = (): MenuItem[] => {
    const staticMenuItems: MenuItem[] = [
        {
            menu: 'MENU.DASHBOARD',
            id: 1,
            permission: [Permissions.VIEW_DASHBOARD],
            path: ''
        },
        {
            menu: 'MENU.ORDERS_QUOTES',
            id: 2,
            permission: [Permissions.VIEW_QUOTE, Permissions.VIEW_ORDER],
            path: 'orders-quotes'
        },
        {
            menu: 'MENU.TASK_MANAGEMENT',
            id: 3,
            permission: [Permissions.VIEW_TASK],
            path: 'task-management'
        },
        {
            menu: 'MENU.COMPANIES',
            id: 4,
            permission: [Permissions.VIEW_COMPANIES],
            path: 'companies'
        },
        // Insert dynamic menu items after "MENU.COMPANIES"
        {
            menu: 'MENU.USERS',
            id: 6,
            permission: [
                Permissions.VIEW_CONTACT_PROFILE,
                Permissions.VIEW_STAFF_USER,
                Permissions.VIEW_VENDOR_USERS,
                Permissions.VIEW_V3_VENDOR_USERS_ONLY
            ],
            path: 'users'
        },
        {
            menu: 'MENU.TASKS',
            id: 7,
            permission: [Permissions.SETTING_VIEW_TASK],
            path: 'tasks'
        },
        {
            menu: 'MENU.BILLING_TERMS',
            id: 8,
            permission: [Permissions.VIEW_BILLING_TERMS],
            path: 'billing-terms'
        },
        {
            menu: 'MENU.REQUEST_CURRENCIES',
            id: 9,
            permission: [Permissions.VIEW_REQUEST_CURRENCIES],
            path: 'request-currencies'
        },
        {
            menu: 'MENU.VENDOR_CURRENCIES',
            id: 10,
            permission: [Permissions.VIEW_VENDOR_CURRENCIES],
            path: 'vendor-currencies'
        },
        {
            menu: 'MENU.LANGUAGES',
            id: 11,
            permission: [Permissions.VIEW_LANGUAGES],
            path: 'languages'
        },
        {
            menu: 'MENU.UNITS',
            id: 12,
            permission: [Permissions.VIEW_UNIT],
            path: 'units'
        },
        {
            menu: 'MENU.IP_INSTRUCTION_DEADLINES',
            id: 13,
            permission: [Permissions.VIEW_IP_INSTRUCTION_DEADLINES],
            path: 'ip-instruction-deadlines'
        },
        {
            menu: 'MENU.PAYMENT_METHODS',
            id: 14,
            permission: [Permissions.VIEW_PAYMENT],
            path: 'payment-methods'
        },
        {
            menu: 'MENU.TAX_FORMS',
            id: 15,
            permission: [Permissions.VIEW_TAX_FORMS],
            path: 'tax-forms'
        },
        {
            menu: 'MENU.BREAKDOWNS',
            id: 17,
            permission: [Permissions.VIEW_BREAKDOWN],
            path: 'break-down'
        },
        {
            menu: 'MENU.PV_SETTING',
            id: 19,
            permission: [Permissions.VIEW_IP_VAULT_SETTINGS],
            path: 'patent-vault-settings'
        }
    ];

    // Dynamic menu items based on permissions
    const permissionBasedMenuItems = getMenuItemsBasedOnPermissions();

    // Insert permission-based items at a specific index (e.g., after "MENU.COMPANIES" which is at index 4)
    const insertIndex = 5;
    staticMenuItems.splice(insertIndex, 0, ...permissionBasedMenuItems);

    return staticMenuItems;
};
const getMenuItemsBasedOnPermissions = (): MenuItem[] => {
    const menuItems: MenuItem[] = [];
    const { isThisFinanceUser } = getInfo();

    if (
        AuthStore.hasPermission(Permissions.VIEW_BILLS) &&
        AuthStore.hasPermission(Permissions.VIEW_INVOICE) &&
        !isThisFinanceUser
    ) {
        menuItems.push({
            menu: 'MENU.ACCOUNTING',
            submenu: [
                {
                    title: 'MENU.BILLS',
                    id: 103,
                    permission: [Permissions.VIEW_BILLS],
                    path: 'accounting/bills'
                },
                {
                    title: 'MENU.INVOICES',
                    id: 104,
                    permission: [Permissions.VIEW_INVOICE],
                    path: 'accounting/invoices'
                }
            ],
            id: 18,
            permission: [Permissions.VIEW_BILLS, Permissions.VIEW_INVOICE],
            path: 'accounting'
        });
    } else {
        if (AuthStore.hasPermission(Permissions.VIEW_BILLS)) {
            menuItems.push({
                menu: 'MENU.BILLS',
                id: 20,
                permission: [Permissions.VIEW_BILLS],
                path: 'bills'
            });
        }
        if (AuthStore.hasPermission(Permissions.VIEW_INVOICE)) {
            menuItems.push({
                menu: 'MENU.INVOICES',
                id: 21,
                permission: [Permissions.VIEW_INVOICE],
                path: 'invoices'
            });
        }
    }

    return menuItems;
};
